import { INotification, INotificationData } from '../notification.types';
import { Notification } from '../notification';

export const mockNotificationsData = (): INotificationData[] => [
  {
    id: 1,
    title: 'Title 1',
    message: 'message 1',
    scheduled_time: new Date('2021-08-20T20:31:31.107Z'),
    owner: 'LeRon harris'
  },
  {
    id: 2,
    title: 'Title 2',
    message: 'message 2 longer text but not long enough',
    scheduled_time: new Date('2021-08-20T20:31:31.107Z'),
    owner: 'LeRon harris'
  }
];

export const mockNotifications = (
  data: INotificationData[] = mockNotificationsData()
): INotification[] => data.map((item) => new Notification(item));
