import { AxiosError } from 'axios';

export class ErrorManager {
  static extractApiError(error: AxiosError): string {
    if (error.response) {
      // client received an error response that falls out of range 2xx
      return error.response.statusText;
    } else if (error.request) {
      // The request was made but no response was received
      return 'No response from server';
    } else {
      // Something happened in setting up the request that triggered an Error
      return 'An error occurred communicating with server';
    }
  }
}
