import { Module } from 'vuex';
import { IRootState } from '@/store';
import { IStatisticState } from './statistic.types';
import { IStatisticData } from '@/models';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: IStatisticState = {
  version: 1,
  statistics: {} as IStatisticData
};

const namespaced = true;

export const statistic: Module<IStatisticState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
