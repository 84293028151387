import { IAdData } from '@/models';
import { IAdService } from './ad.types';
import { AxiosInstance } from 'axios';
import { objectToFormData } from '@/utils/form-data';

export class AdService implements IAdService {
  private baseEndpoint: string;
  private endpoint: string;

  constructor(private axios: AxiosInstance) {
    this.baseEndpoint = 'v1/apps/';
    this.endpoint = 'ads/';
  }

  fetchAds(appId: string): Promise<IAdData[]> {
    return this.axios
      .get<IAdData[]>(`${this.baseEndpoint}${appId}/${this.endpoint}`)
      .then(({ data }) => data);
  }

  fetchAd(appId: string, id: number): Promise<IAdData> {
    return this.axios
      .get<IAdData>(`${this.baseEndpoint}${appId}/${this.endpoint}${id}/`)
      .then(({ data }) => data);
  }

  createAd(appId: string, data: IAdData): Promise<IAdData> {
    const formData = objectToFormData(data);
    return this.axios
      .post<IAdData>(`${this.baseEndpoint}${appId}/${this.endpoint}`, formData)
      .then(({ data }) => data);
  }

  updateAd(appId: string, data: IAdData): Promise<IAdData> {
    const formData = objectToFormData(data);
    return this.axios
      .patch<IAdData>(
        `${this.baseEndpoint}${appId}/${this.endpoint}${data.id}/`,
        formData
      )
      .then(({ data }) => data);
  }

  deleteAd(appId: string, id: number): Promise<void> {
    return this.axios.delete(
      `${this.baseEndpoint}${appId}/${this.endpoint}${id}/`
    );
  }

  updateAdOrder(data: IAdData): Promise<IAdData> {
    return this.axios
      .put(
        `${this.baseEndpoint}${data.app}/${this.endpoint}${data.id}/set_order/`,
        {
          order: data.order
        }
      )
      .then(({ data }) => data);
  }
}
