import { Module } from 'vuex';
import { IRootState } from '@/store';
import { INotificationState } from './notification.types';
import { INotificationData } from '@/models';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: INotificationState = {
  version: 1,
  notifications: [] as INotificationData[]
};

const namespaced = true;

export const notification: Module<INotificationState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
