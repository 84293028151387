// API Data
export interface IAltoleapAppData {
  id?: string;
  name: string;
}

export interface IAltoleapAppFieldErrorData {
  name?: string[];
}

// Business Logic Interface
export interface IAltoleapApp extends IAltoleapAppData {
  validate(): boolean;
}

export interface IAltoleapAppFieldError extends IAltoleapAppFieldErrorData {
  clearErrors(): void;
}

// Constants - Used for validation
export const APP_UUID_REGEXP =
  /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
