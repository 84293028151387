import {
  IOrganization,
  IOrganizationData,
  ORGANIZATION_MAX_NAME_LENGTH
} from './organization.types';

export class Organization implements IOrganization {
  readonly id?: number;
  readonly name: string;

  constructor(data: IOrganizationData) {
    if (data.id) {
      this.id = data.id;
    }
    this.name = data.name;
  }

  validate(): boolean {
    // ensure organization name is valid
    if (!this.name || this.name.length > ORGANIZATION_MAX_NAME_LENGTH) {
      return false;
    }

    return true;
  }
}
