// Locale
import { UserVuetifyPreset } from 'vuetify';
import colors from 'vuetify/lib/util/colors';
import { IThemeConfig } from './shims-themeConfig';

export const themeConfig: IThemeConfig = {
  disableCustomizer: false,

  rtl: false,
  verticalSidebarMini: false,
  verticalSidebarDrawer: true,
  verticalCompactSidebarDrawer: true,
  verticalSaasSidebarDrawer: true,
  showBreadcrumb: true,

  layout: 'HorizontalBar',
  isLoading: false,

  isDark: false,
  verticalSidebarDrawerColor: 'white',
  appBarColor: 'white'
};

//const mq = window.matchMedia('(prefers-color-scheme: dark)');

export const themePreset: UserVuetifyPreset = {
  breakpoint: {
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920
    }
  },

  rtl: false,

  theme: {
    dark: false, //mq.matches,

    default: 'light',
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: true,
      minifyTheme: undefined,
      themeCache: undefined
    },

    themes: {
      light: {
        primary: '#00ADEE',
        secondary: '#304156',
        success: colors.green,
        danger: colors.red,
        warning: colors.deepOrange,
        info: colors.indigo,

        dark: '#242939',

        background: colors.grey.lighten5
      },

      dark: {
        primary: '#00ADEE',
        secondary: '#304156',
        success: colors.green,
        danger: colors.red,
        warning: colors.deepOrange,
        info: colors.indigo
      }
    }
  }
};
