/* eslint-disable @typescript-eslint/no-explicit-any */
// LF - 2021-09-28 - We are intentionally allowing any in this case.
export function objectToFormData(
  object: any,
  form?: FormData,
  namespace = '',
  isArray = false
): FormData {
  const formData = form || new FormData();

  for (const property in object) {
    if (
      !Object.prototype.hasOwnProperty.call(object, property) ||
      !object[property]
    )
      continue;

    const formKey = namespace
      ? isArray
        ? `${namespace}${property}`
        : `${namespace}.${property}`
      : property;

    if (object[property] instanceof Date)
      // handle Dates
      formData.append(formKey, object[property].toISOString());
    else if (object[property] instanceof Array) {
      // handle Arrays
      object[property].forEach((element: any, index: any) => {
        const tempFormKey = `${formKey}[${index}]`;
        objectToFormData(element, formData, tempFormKey, true);
      });
    } else if (object[property] instanceof File) {
      // Handle Files
      formData.append(formKey, object[property], object[property].name);
    } else if (typeof object[property] === 'object') {
      // Handle Generic Objects
      objectToFormData(object[property], formData, formKey);
    } else {
      formData.append(formKey, object[property].toString());
    }
  }
  return formData;
}
