import {
  IAdActions,
  AdContext,
  FetchAdActionPayload,
  CreateAdActionPayload,
  UpdateAdActionPayload
} from './actions.types';
import { IAdData } from '@/models';

export const actions: IAdActions = {
  fetchAds(context: AdContext, payload: string): Promise<void> {
    return this.$services.ad.fetchAds(payload).then((ads: IAdData[]) => {
      context.commit('SET_ADS', ads);
    });
  },

  fetchAd(context: AdContext, payload: FetchAdActionPayload): Promise<void> {
    return this.$services.ad
      .fetchAd(payload.appId, payload.id)
      .then((ad: IAdData) => {
        const index = context.state.ads.findIndex(
          (v: IAdData) => v.id === ad.id
        );
        if (index === -1) {
          context.commit('ADD_AD', ad);
        } else {
          context.commit('UPDATE_AD', ad);
        }
      });
  },

  createAd(context: AdContext, payload: CreateAdActionPayload): Promise<void> {
    return this.$services.ad
      .createAd(payload.appId, payload.data)
      .then((ad: IAdData) => context.commit('ADD_AD', ad));
  },

  updateAd(context: AdContext, payload: UpdateAdActionPayload): Promise<void> {
    return this.$services.ad
      .updateAd(payload.appId, payload.data)
      .then((ad: IAdData) => {
        const index = context.state.ads.findIndex(
          (v: IAdData) => v.id === ad.id
        );
        if (index === -1) {
          context.commit('ADD_AD', ad);
        } else {
          context.commit('UPDATE_AD', ad);
        }
      });
  },

  deleteAd(context: AdContext, payload: FetchAdActionPayload): Promise<void> {
    return this.$services.ad.deleteAd(payload.appId, payload.id).then(() => {
      context.commit('REMOVE_AD', payload.id);
    });
  },

  updateAdOrder(context: AdContext, payload: IAdData): Promise<void> {
    return this.$services.ad.updateAdOrder(payload).then((ad: IAdData) => {
      const index = context.state.ads.findIndex((v: IAdData) => v.id === ad.id);
      if (index === -1) {
        context.commit('ADD_AD', ad);
      } else {
        context.commit('UPDATE_AD', ad);
      }
      context.commit('SORT_ADS_BY_ORDER');
    });
  }
};
