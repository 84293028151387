import { IAdState } from '@/store/modules/ad';
import { IAdGetters } from './getters.types';
import { IAd, Ad } from '@/models';

export const getters: IAdGetters = {
  getAds(state: IAdState): IAd[] {
    return state.ads.map((data) => new Ad(data));
  },

  getAdById:
    (state: IAdState) =>
    (id: number): IAd | undefined => {
      const data = state.ads.find((ad) => ad.id === id);
      if (!data) {
        return undefined;
      }

      return new Ad(data);
    }
};
