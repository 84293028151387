import { INotificationState } from '@/store/modules/notification';
import { INotificationGetters } from './getters.types';
import { INotification, Notification } from '@/models';

export const getters: INotificationGetters = {
  getNotifications(state: INotificationState): INotification[] {
    return state.notifications.map((data) => new Notification(data));
  },

  getNotificationById:
    (state: INotificationState) =>
    (id: number): INotification | undefined => {
      const data = state.notifications.find(
        (notification) => notification.id === id
      );
      if (!data) {
        return undefined;
      }

      return new Notification(data);
    }
};
