














import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'Avatar',

  props: {
    size: { type: Number, default: 35 },
    color: { type: String, default: 'white' },
    density: {
      type: String as PropType<string>,
      default: 'default'
    },

    image: { type: String, required: false },
    text: { type: String, required: true }
  },

  computed: {
    initialsSize(): string {
      switch (this.density) {
        case 'x-large':
          return 'display-2';
        case 'large':
          return 'display-1';
        case 'default':
          return '';
        case 'small':
          return 'text-14';
        case 'x-small':
          return 'caption';
        default:
          return this.density;
      }
    }
  }
});
