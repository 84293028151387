import { IAdState } from '@/store/modules/ad';
import { IAdMutations } from './mutations.types';
import { IAdData } from '@/models';

export const mutations: IAdMutations = {
  SET_ADS(state: IAdState, payload: IAdData[]): void {
    state.ads = payload;
  },

  ADD_AD(state: IAdState, payload: IAdData): void {
    state.ads.push(payload);
  },

  UPDATE_AD(state: IAdState, payload: IAdData): void {
    const index = state.ads.findIndex((ad: IAdData) => ad.id === payload.id);

    state.ads.splice(index, 1, payload);
  },

  REMOVE_AD(state: IAdState, payload: number): void {
    const index = state.ads.findIndex((ad: IAdData) => ad.id === payload);

    state.ads.splice(index, 1);
  },

  SORT_ADS_BY_ORDER(state: IAdState): void {
    state.ads.sort((ad1, ad2) => {
      if (ad1.order && ad2.order) return ad1.order - ad2.order;
      return 0;
    });
  }
};
