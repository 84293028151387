import { IAltoleapAppState } from '@/store/modules/altoleapapp';
import { IAltoleapAppMutations } from './mutations.types';
import { IAltoleapAppData } from '@/models';

export const mutations: IAltoleapAppMutations = {
  SET_ALTOLEAP_APPS(
    state: IAltoleapAppState,
    payload: IAltoleapAppData[]
  ): void {
    state.apps = payload;
  },

  ADD_ALTOLEAP_APP(state: IAltoleapAppState, payload: IAltoleapAppData): void {
    state.apps.push(payload);
  },

  UPDATE_ALTOLEAP_APP(
    state: IAltoleapAppState,
    payload: IAltoleapAppData
  ): void {
    const index = state.apps.findIndex(
      (app: IAltoleapAppData) => app.id === payload.id
    );

    state.apps.splice(index, 1, payload);
  },

  REMOVE_ALTOLEAP_APP(state: IAltoleapAppState, payload: string): void {
    const index = state.apps.findIndex(
      (app: IAltoleapAppData) => app.id === payload
    );

    state.apps.splice(index, 1);
  }
};
