// API Data
export interface INotificationData {
  id?: number;
  title: string;
  message: string;
  data?: string;
  // TODO: Add toString() implementation of data class when sending to api
  /** Used for scheduling notification */
  scheduled_time?: Date | string;
  owner?: string;
  created_at?: Date | string;
}

export interface INotificationFieldErrorData {
  title?: string[];
  message?: string[];
  scheduled_time?: string[];
  data?: string[];
}

// Business Logic Interface
export interface INotification extends INotificationData {
  validate(): boolean;
}

export interface INotificationFieldError extends INotificationFieldErrorData {
  clearErrors(): void;
}

// Constants - Used for validation
export const NOTIFICATION_MESSAGE_MAX_LENGTH = 255;
export const NOTIFICATION_TITLE_MAX_LENGTH = 128;
