import { DeviceStatistic } from './device-statistic';
import { IDeviceStatistic } from './device-statistic.types';
import {
  IStatisticsDetail,
  IStatisticsDetailData,
  IStatisticsDetailFieldError,
  IStatisticsDetailFieldErrorData
} from './statistics-detail.types';

export class StatisticsDetail implements IStatisticsDetail {
  start_date: Date | string;
  end_date: Date | string;
  statistics: IDeviceStatistic;

  constructor(data: IStatisticsDetailData) {
    this.start_date = data.start_date;
    this.end_date = data.end_date;
    this.statistics = new DeviceStatistic(data.statistics);
  }

  validate(): boolean {
    if (!this.start_date) {
      return false;
    }
    if (!this.end_date) {
      return false;
    }
    if (!this.statistics) {
      return false;
    }
    return true;
  }
}

export class StatisticsDetailFieldError implements IStatisticsDetailFieldError {
  start_date?: string[] = [];
  end_date?: string[] = [];
  statistics?: string[] = [];

  constructor(data?: IStatisticsDetailFieldErrorData) {
    if (data) {
      if (data.start_date) {
        this.start_date = data.start_date;
      }
      if (data.end_date) {
        this.end_date = data.end_date;
      }
      if (data.statistics) {
        this.statistics = data.statistics;
      }
    }
  }

  clearErrors(): void {
    this.start_date?.splice(0, this.start_date.length);
    this.end_date?.splice(0, this.end_date.length);
    this.statistics?.splice(0, this.statistics.length);
  }

  static isStatisticsDetailFieldError(
    data: IStatisticsDetailFieldErrorData
  ): boolean {
    if (
      (data.start_date && Array.isArray(data.start_date)) ||
      (data.end_date && Array.isArray(data.end_date)) ||
      (data.statistics && Array.isArray(data.statistics))
    ) {
      return true;
    }
    return false;
  }
}
