import { IAltoleapAppData } from '@/models';
import { IAltoleapAppService } from './altoleapapp.types';
import { AxiosInstance } from 'axios';

export class AltoleapAppService implements IAltoleapAppService {
  private endpoint: string;

  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/apps/';
  }

  fetchAltoleapApps(): Promise<IAltoleapAppData[]> {
    return this.axios
      .get<IAltoleapAppData[]>(`${this.endpoint}`)
      .then(({ data }) => data);
  }

  fetchAltoleapApp(id: string): Promise<IAltoleapAppData> {
    return this.axios
      .get<IAltoleapAppData>(`${this.endpoint}${id}/`)
      .then(({ data }) => data);
  }

  createAltoleapApp(data: IAltoleapAppData): Promise<IAltoleapAppData> {
    return this.axios
      .post<IAltoleapAppData>(`${this.endpoint}`, data)
      .then(({ data }) => data);
  }

  updateAltoleapApp(data: IAltoleapAppData): Promise<IAltoleapAppData> {
    return this.axios
      .patch<IAltoleapAppData>(`${this.endpoint}${data.id}/`, data)
      .then(({ data }) => data);
  }

  deleteAltoleapApp(id: string): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`);
  }
}
