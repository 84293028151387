import { Module } from 'vuex';
import { IRootState } from '@/store';
import { IAltoleapAppState } from './altoleapapp.types';
import { IAltoleapAppData } from '@/models';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: IAltoleapAppState = {
  version: 1,
  apps: [] as IAltoleapAppData[]
};

const namespaced = true;

export const altoleapApp: Module<IAltoleapAppState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
