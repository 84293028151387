import {
  IStatisticsDetailData,
  IStatisticsDetail
} from './statistics-detail.types';
// API Data
export interface IStatisticData {
  ios: number;
  android: number;
  web: number;
  active: number;
  inactive: number;
  details: IStatisticsDetailData[];
}

export interface IStatisticFieldErrorData {
  ios?: string[];
  android?: string[];
  web?: string[];
  active?: string[];
  inactive?: string[];
  details?: string[];
}

// Business Logic Interface
export interface IStatistic extends IStatisticData {
  details: IStatisticsDetail[];
  validate(): boolean;
}

export interface IStatisticFieldError extends IStatisticFieldErrorData {
  clearErrors(): void;
}

// Constants - Used for validation
export enum StatisticIntervalType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly'
}
