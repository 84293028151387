import {
  IAd,
  IAdData,
  IAdFieldError,
  IAdFieldErrorData,
  UUID_REGEXP
} from './ad.types';

export class Ad implements IAd {
  readonly id?: number;
  readonly image: string | File = '';
  readonly url: string = '';
  readonly app: string = '';
  readonly order?: number;

  readonly created_at?: Date | string;
  readonly updated_at?: Date | string;

  constructor(data?: IAdData) {
    if (data) {
      this.app = data.app;
      this.image = data.image;
      this.url = data.url;

      if (data.order) {
        this.order = data.order;
      }

      if (data.id) {
        this.id = data.id;
      }

      if (data.created_at) {
        this.created_at = data.created_at;
      }
      if (data.updated_at) {
        this.updated_at = data.updated_at;
      }
    }
  }

  validate(): boolean {
    // ! LH - Check that the app id is a uuid
    if (!UUID_REGEXP.test(this.app)) {
      return false;
    }

    return true;
  }
}

export class AdFieldError implements IAdFieldError {
  readonly image: string[] = [];
  readonly url: string[] = [];
  readonly app: string[] = [];

  constructor(data?: IAdFieldErrorData) {
    if (data) {
      if (data.image) {
        this.image = data.image;
      }
      if (data.url) {
        this.url = data.url;
      }
      if (data.app) {
        this.app = data.app;
      }
    }
  }

  clearErrors(): void {
    this.image.splice(0, this.image.length);
    this.url.splice(0, this.url.length);
    this.app.splice(0, this.app.length);
  }

  static isAdFieldError(data: IAdFieldErrorData): boolean {
    if (data.image && Array.isArray(data.image)) {
      return true;
    }
    if (data.url && Array.isArray(data.url)) {
      return true;
    }
    if (data.app && Array.isArray(data.app)) {
      return true;
    }
    return false;
  }
}
