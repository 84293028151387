import { IAuthData, IAuthLoginData, IUserData } from '@/models';
import { IAuthService } from './auth.types';
import { AxiosInstance } from 'axios';
import { objectToFormData } from '@/utils/form-data';

export class AuthService implements IAuthService {
  private endpoint: string;

  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/accounts/auth/';
  }

  login(data: IAuthLoginData): Promise<IAuthData> {
    return this.axios
      .post<IAuthData>(`${this.endpoint}login/`, data, {
        // Authorization field not available for login request
        headers: {
          Authorization: null
        }
      })
      .then(({ data }) => data);
  }

  changePassword(
    new_password1: string,
    new_password2: string
  ): Promise<{ detail: string }> {
    return this.axios
      .post<{ detail: string }>(`${this.endpoint}password/change/`, {
        new_password1,
        new_password2
      })
      .then(({ data }) => data);
  }

  logout(): Promise<void> {
    return this.axios.post(`${this.endpoint}logout/`);
  }

  refreshToken(accessToken: string): Promise<IAuthData> {
    return this.axios
      .post<IAuthData>(
        `${this.endpoint}token-refresh/`,
        { token: accessToken },
        {
          // Authorization field not available for login request
          headers: {
            Authorization: null
          }
        }
      )
      .then(({ data }) => data);
  }

  fetchUser(): Promise<IUserData> {
    return this.axios
      .get<IUserData>(`${this.endpoint}user/`)
      .then(({ data }) => data);
  }

  updateUser(data: IUserData): Promise<IUserData> {
    const formData = objectToFormData(data);

    return this.axios
      .patch<IUserData>(`${this.endpoint}user/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 5000
      })
      .then(({ data }) => data);
  }
}
