import { IAuthState } from '@/store/modules/auth';
import { IAuthGetters } from './getters.types';
import { IUser, User } from '@/models';

export const getters: IAuthGetters = {
  getUser(state: IAuthState): IUser | undefined {
    if (state.user) {
      return new User(state.user);
    }
    return undefined;
  }
};
