import { INotificationState } from '@/store/modules/notification';
import { INotificationMutations } from './mutations.types';
import { INotificationData } from '@/models';

export const mutations: INotificationMutations = {
  SET_NOTIFICATIONS(
    state: INotificationState,
    payload: INotificationData[]
  ): void {
    state.notifications = payload;
  },

  ADD_NOTIFICATION(
    state: INotificationState,
    payload: INotificationData
  ): void {
    state.notifications.push(payload);
  },

  UPDATE_NOTIFICATION(
    state: INotificationState,
    payload: INotificationData
  ): void {
    const index = state.notifications.findIndex(
      (notification: INotificationData) => notification.id === payload.id
    );

    state.notifications.splice(index, 1, payload);
  },

  REMOVE_NOTIFICATION(state: INotificationState, payload: number): void {
    const index = state.notifications.findIndex(
      (notification: INotificationData) => notification.id === payload
    );

    state.notifications.splice(index, 1);
  }
};
