import { axios } from '@/plugins/axios';
import { IProvider } from './provider.types';
import { AuthService } from './auth';
import { LocalStorageService } from './storage';
import { StatisticService } from './statistic';
import { NotificationService } from './notification';
import { AltoleapAppService } from './altoleapapp';
import { AdService } from './ad';

export const provider = (): IProvider => ({
  auth: new AuthService(axios),
  storage: new LocalStorageService(),
  statistic: new StatisticService(axios),
  notification: new NotificationService(axios),
  altoleapApp: new AltoleapAppService(axios),
  ad: new AdService(axios)
});
