import { IAltoleapAppActions, AltoleapAppContext } from './actions.types';
import { IAltoleapAppData } from '@/models';

export const actions: IAltoleapAppActions = {
  fetchAltoleapApps(context: AltoleapAppContext): Promise<void> {
    return this.$services.altoleapApp
      .fetchAltoleapApps()
      .then((apps: IAltoleapAppData[]) => {
        context.commit('SET_ALTOLEAP_APPS', apps);
      });
  },

  fetchAltoleapApp(
    context: AltoleapAppContext,
    payload: string
  ): Promise<void> {
    return this.$services.altoleapApp
      .fetchAltoleapApp(payload)
      .then((app: IAltoleapAppData) => {
        const index = context.state.apps.findIndex(
          (v: IAltoleapAppData) => v.id === app.id
        );
        if (index === -1) {
          context.commit('ADD_ALTOLEAP_APP', app);
        } else {
          context.commit('UPDATE_ALTOLEAP_APP', app);
        }
      });
  },

  createAltoleapApp(
    context: AltoleapAppContext,
    payload: IAltoleapAppData
  ): Promise<void> {
    return this.$services.altoleapApp
      .createAltoleapApp(payload)
      .then((app: IAltoleapAppData) => context.commit('ADD_ALTOLEAP_APP', app));
  },

  updateAltoleapApp(
    context: AltoleapAppContext,
    payload: IAltoleapAppData
  ): Promise<void> {
    return this.$services.altoleapApp
      .updateAltoleapApp(payload)
      .then((app: IAltoleapAppData) => {
        const index = context.state.apps.findIndex(
          (v: IAltoleapAppData) => v.id === app.id
        );
        if (index === -1) {
          context.commit('ADD_ALTOLEAP_APP', app);
        } else {
          context.commit('UPDATE_ALTOLEAP_APP', app);
        }
      });
  },

  deleteAltoleapApp(
    context: AltoleapAppContext,
    payload: string
  ): Promise<void> {
    return this.$services.altoleapApp.deleteAltoleapApp(payload).then(() => {
      context.commit('REMOVE_ALTOLEAP_APP', payload);
    });
  }
};
