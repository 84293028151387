import { IAltoleapApp, IAltoleapAppData } from '../altoleapapp.types';
import { AltoleapApp } from '../altoleapapp';

export const mockAltoleapAppsData = (): IAltoleapAppData[] => [
  {
    id: '73f10886-0f0a-45d8-ae35-03b18f86c2e5',
    name: 'Altoleap'
  },
  {
    id: '73f63193-0f0a-45d8-ae35-03b18f86c2e5',
    name: 'Altoleap MyApps'
  }
];

export const mockAltoleapApps = (
  data: IAltoleapAppData[] = mockAltoleapAppsData()
): IAltoleapApp[] => data.map((item) => new AltoleapApp(item));
