import { IAuthActions, AuthContext } from './actions.types';
import { IAuthData, IAuthLoginData, IUserData, Token } from '@/models';
import { IAuthChangePasswordPayload } from '.';

export const actions: IAuthActions = {
  login(context: AuthContext, payload: IAuthLoginData): Promise<void> {
    return this.$services.auth.login(payload).then((auth: IAuthData) => {
      // save token to local storage
      this.$services.storage.saveAccessToken(auth.token);

      // save user data to vuex state
      context.commit('SET_AUTH_USER', auth.user);

      // TODO -  LH  - 2021-09-20 look into this more to find out about automating this
      context.dispatch('autoRefresh');
    });
  },

  logout(_: AuthContext): Promise<void> {
    return this.$services.auth.logout().finally(() => {
      // This is intentionally done in finally block -
      // Regardless of success or error applicable data are cleared
      // clear token in local storage
      this.$services.storage.clearAccessToken();
      // *This clears the store data
      location.reload();
    });
  },

  changePassword(
    _: AuthContext,
    payload: IAuthChangePasswordPayload
  ): Promise<void> {
    return this.$services.auth
      .changePassword(payload.new_password1, payload.new_password2)
      .then();
  },

  refreshToken(context: AuthContext, payload: string): Promise<void> {
    return this.$services.auth.refreshToken(payload).then((auth: IAuthData) => {
      // save token to local storage
      this.$services.storage.saveAccessToken(auth.token);

      context.dispatch('autoRefresh');
    });
  },

  fetchUser(context: AuthContext): Promise<void> {
    return this.$services.auth.fetchUser().then((user: IUserData) => {
      context.commit('SET_AUTH_USER', user);
    });
  },

  updateUser(context: AuthContext, payload: IUserData): Promise<void> {
    return this.$services.auth.updateUser(payload).then((user: IUserData) => {
      context.commit('SET_AUTH_USER', user);
    });
  },

  // TODO -  LH  - 2021-09-20 look into this more to find out about automating timeouts
  autoRefresh(context: AuthContext) {
    try {
      const access_token = this.$services.storage.getAccessToken();
      if (!access_token) {
        throw new Error('No valid token');
      } else {
        const token = new Token(access_token);
        let timeUntilRefresh = token.exp - token.orig_iat; //exp and original_issued_at is represented in seconds since epoch
        timeUntilRefresh -= Token.REFRESH_TOKEN_THRESHOLD_IN_MINUTES * 60; // Refresh 15 minutes before it expires
        // In case you want to cancel this task on logout
        // logout will clear the timeout
        setTimeout(
          () => context.dispatch('refreshToken', access_token),
          timeUntilRefresh * 1000
        );
      }
    } catch (e) {
      context.dispatch('logout');
    }
  },

  isUserTokenExpired(_: AuthContext) {
    const user_access_token = this.$services.storage.getAccessToken();

    if (user_access_token) {
      return new Token(user_access_token).isExpired();
    }

    return true;
  }
};
