import { IStatistic, IStatisticData } from '../statistic.types';
import { Statistic } from '../statistic';

export const mockStatisticsData = (): IStatisticData => ({
  ios: 5,
  android: 7,
  web: 1,
  active: 13,
  inactive: 0,
  details: [
    {
      start_date: '2021-09-01',
      end_date: '2021-09-02',
      statistics: { ios: 2, android: 3, web: 0 }
    },
    {
      start_date: '2021-09-02',
      end_date: '2021-09-03',
      statistics: { ios: 3, android: 4, web: 1 }
    }
  ]
});

export const mockStatistics = (
  data: IStatisticData = mockStatisticsData()
): IStatistic => new Statistic(data);
