import { Module } from 'vuex';
import { IRootState } from '@/store';
import { IAdState } from './ad.types';
import { IAdData } from '@/models';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: IAdState = {
  version: 1,
  ads: [] as IAdData[]
};

const namespaced = true;

export const ad: Module<IAdState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
