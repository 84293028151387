import {
  INotification,
  INotificationData,
  INotificationFieldError,
  INotificationFieldErrorData,
  NOTIFICATION_MESSAGE_MAX_LENGTH,
  NOTIFICATION_TITLE_MAX_LENGTH
} from './notification.types';

export class Notification implements INotification {
  readonly id?: number;
  readonly title: string = '';
  readonly message: string = '';
  readonly data: string = '';
  readonly scheduled_time?: Date | string;
  readonly owner?: string;
  readonly created_at?: Date | string;

  constructor(data?: INotificationData) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      this.title = data.title;
      this.message = data.message;

      if (data.data) {
        this.data = data.data;
      }
      if (data.scheduled_time) {
        this.scheduled_time = data.scheduled_time;
      }
      if (data.owner) {
        this.owner = data.owner;
      }
      if (data.created_at) {
        this.created_at = data.created_at;
      }
    }
  }

  validate(): boolean {
    if (!this.title || this.title.length > NOTIFICATION_TITLE_MAX_LENGTH) {
      return false;
    }
    if (
      !this.message &&
      this.message.length > NOTIFICATION_MESSAGE_MAX_LENGTH
    ) {
      return false;
    }

    return true;
  }
}

export class NotificationFieldError implements INotificationFieldError {
  readonly title: string[] = [];
  readonly message: string[] = [];
  readonly scheduled_time: string[] = [];
  readonly data: string[] = [];

  constructor(data?: INotificationFieldErrorData) {
    if (data) {
      if (data.title) {
        this.title = data.title;
      }
      if (data.message) {
        this.message = data.message;
      }
      if (data.scheduled_time) {
        this.scheduled_time = data.scheduled_time;
      }
      if (data.data) {
        this.data = data.data;
      }
    }
  }

  clearErrors(): void {
    this.title.splice(0, this.title.length);
    this.message.splice(0, this.message.length);
    this.scheduled_time.splice(0, this.scheduled_time.length);
    this.data.splice(0, this.data.length);
  }

  static isNotificationFieldError(data: INotificationFieldErrorData): boolean {
    if (data.title && Array.isArray(data.title)) {
      return true;
    }
    if (data.message && Array.isArray(data.message)) {
      return true;
    }
    if (data.scheduled_time && Array.isArray(data.scheduled_time)) {
      return true;
    }
    if (data.data && Array.isArray(data.data)) {
      return true;
    }
    return false;
  }
}
