import {
  IAltoleapApp,
  IAltoleapAppData,
  IAltoleapAppFieldError,
  IAltoleapAppFieldErrorData,
  APP_UUID_REGEXP
} from './altoleapapp.types';

export class AltoleapApp implements IAltoleapApp {
  readonly id?: string;
  readonly name: string;

  constructor(data: IAltoleapAppData) {
    this.name = data.name ?? '';

    if (data.id) {
      this.id = data.id;
    }
  }

  validate(): boolean {
    // ! LH - Check that the id is a uuid
    if (this.id && !APP_UUID_REGEXP.test(this.id)) {
      return false;
    }
    return true;
  }
}

export class AltoleapAppFieldError implements IAltoleapAppFieldError {
  name: string[] = [];

  constructor(data?: IAltoleapAppFieldErrorData) {
    if (data) {
      if (data.name) {
        this.name = data.name;
      }
    }
  }

  clearErrors(): void {
    this.name.splice(0, this.name.length);
  }

  static isAltoleapAppFieldError(data: IAltoleapAppFieldErrorData): boolean {
    if (data.name && Array.isArray(data.name)) {
      return true;
    }
    return false;
  }
}
