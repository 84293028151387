import { IToken, ITokenData } from './token.types';
import jwt_decode from 'jwt-decode';

export class Token implements IToken {
  readonly user_id: number;
  readonly username: string = '';
  readonly exp: number = 0;
  readonly email: string = '';
  readonly orig_iat: number = 0;

  constructor(token: string) {
    const data = jwt_decode<ITokenData>(token);

    this.user_id = data.user_id;
    this.username = data.username;
    this.exp = data.exp;
    this.email = data.email;
    this.orig_iat = data.orig_iat;
  }

  validate(): boolean {
    if (!this.exp || !this.orig_iat) {
      return false;
    }
    return true;
  }

  isExpired(): boolean {
    return Date.now() >= this.exp * 1000;
  }

  needsRefresh(): boolean {
    const time_left = this.exp * 1000 - Date.now();
    const time_left_in_seconds = time_left / 1000;
    const time_left_in_minutes = time_left_in_seconds / 60;

    //time left must be less than the refresh threshold but greater than the expiration threshold
    return (
      time_left_in_minutes < Token.REFRESH_TOKEN_THRESHOLD_IN_MINUTES &&
      time_left_in_minutes > 0
    );
  }

  static REFRESH_TOKEN_THRESHOLD_IN_MINUTES = 15;
}
