import {
  IDeviceStatistic,
  IDeviceStatisticData,
  IDeviceStatisticFieldError,
  IDeviceStatisticFieldErrorData
} from './device-statistic.types';

export class DeviceStatistic implements IDeviceStatistic {
  ios: number;
  android: number;
  web: number;

  constructor(data: IDeviceStatisticData) {
    this.ios = data.ios ? data.ios : 0;
    this.android = data.android ? data.android : 0;
    this.web = data.web ? data.web : 0;
  }

  validate(): boolean {
    if (!this.ios) {
      return false;
    }
    if (!this.android) {
      return false;
    }
    if (!this.web) {
      return false;
    }
    return true;
  }
}

export class DeviceStatisticFieldError implements IDeviceStatisticFieldError {
  ios?: string[] = [];
  android?: string[] = [];
  web?: string[] = [];

  constructor(data?: IDeviceStatisticFieldErrorData) {
    if (data) {
      if (data.ios) {
        this.ios = data.ios;
      }
      if (data.android) {
        this.android = data.android;
      }
      if (data.web) {
        this.web = data.web;
      }
    }
  }

  clearErrors(): void {
    this.ios?.splice(0, this.ios.length);
    this.android?.splice(0, this.android.length);
    this.web?.splice(0, this.web.length);
  }

  static isDeviceStatisticFieldError(
    data: IDeviceStatisticFieldErrorData
  ): boolean {
    if (
      (data.ios && Array.isArray(data.ios)) ||
      (data.android && Array.isArray(data.android)) ||
      (data.web && Array.isArray(data.web))
    ) {
      return true;
    }
    return false;
  }
}
