import axios, { AxiosInstance } from 'axios';
import { Store } from 'vuex';
import { IRootState } from '@/store/store.types';

type axiosInterceptorPluginType = (
  api: AxiosInstance,
  store: Store<IRootState>
) => void;

const createRequestInterceptor = (
  store: Store<IRootState>,
  api: AxiosInstance
) => {
  api.interceptors.request.use(function (config) {
    if (store.$services.storage.getAccessToken()) {
      config.headers.common[
        'Authorization'
      ] = `Bearer ${store.$services.storage.getAccessToken()}`;
    }
    return config;
  }, undefined);
};

const createResponseInterceptor = (
  store: Store<IRootState>,
  api: AxiosInstance
) => {
  api.interceptors.response.use(undefined, async (error) => {
    if (axios.isAxiosError(error) && error.response) {
      const { status } = error.response;

      switch (status) {
        case 401:
          store.dispatch('auth/logout');

          return Promise.reject(error);
        default:
          return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  });
};

export const axiosInterceptorPlugin: axiosInterceptorPluginType = (
  api: AxiosInstance,
  store: Store<IRootState>
) => {
  createRequestInterceptor(store, api);
  createResponseInterceptor(store, api);
};

export default axiosInterceptorPlugin;
