import { ILocalStorageService } from './storage.types';

export class LocalStorageService implements ILocalStorageService {
  private ACCESS_TOKEN_KEY = 'access__token';

  getAccessToken(): string | null {
    return localStorage.getItem(this.ACCESS_TOKEN_KEY);
  }

  saveAccessToken(data: string): void {
    localStorage.setItem(this.ACCESS_TOKEN_KEY, data);
  }

  clearAccessToken(): void {
    localStorage.removeItem(this.ACCESS_TOKEN_KEY);
  }
}
