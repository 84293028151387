// API Data
export interface IOrganizationData {
  id?: number;
  name: string;
}

// Business Logic Interface
export interface IOrganization extends IOrganizationData {
  validate(): boolean;
}

// Constants - Used for validation
export const ORGANIZATION_MAX_NAME_LENGTH = 255;
