<template>
  <v-list-group
    v-model="listModel"
    :prepend-icon="item.icon"
    :sub-group="subGroup"
    no-action
    flat
    :active-class="` ${
      isDark && getThemeMode.verticalSidebarDrawerColor != 'white'
        ? getThemeMode.verticalSidebarDrawerColor + ' darken-1 white--text'
        : 'grey lighten-4'
    }`"
    class="pl-0"
  >
    <template v-slot:activator>
      <!-- LF-2021-6-05 -- This cause title to not display
        <v-list-item-icon
        v-if="text"
        class="v-list-item__icon--text"
        v-text="computedText"
      /> -->

      <v-list-item-content>
        <v-list-item-title class="text-14" v-text="item.title" />
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in children">
      <base-item-sub-group
        v-if="child.children"
        :key="`sub-group-${i}`"
        :item="child"
        class="pl-0"
      />

      <base-item
        v-else
        :key="`item-${i}`"
        class=""
        :item="child"
        :text="false"
      />
    </template>
  </v-list-group>
</template>

<script>
import { mapGetters } from 'vuex';

// Utilities
import kebabCase from 'lodash/kebabCase';
import Themeable from 'vuetify/lib/mixins/themeable';

export default {
  name: 'ItemGroup',

  mixins: [Themeable],

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        subHeader: undefined,
        children: []
      })
    },

    subGroup: {
      type: Boolean,
      default: false
    },

    text: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      listModel: 0
    };
  },

  computed: {
    ...mapGetters(['getThemeMode']),

    children() {
      return this.item.children.map((item) => ({
        ...item,
        to: !item.to ? undefined : `/${this.item.group}/${item.to}`
      }));
    },

    computedText() {
      if (!this.item || !this.item.title) return '';

      let text = '';

      this.item.title.split(' ').forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },

    group() {
      return this.genGroup(this.item.children);
    }
  },

  methods: {
    genGroup(children) {
      return children
        .filter((item) => item.to)
        .map((item) => {
          const parent = item.group || this.item.group;
          let group = `${parent}/${kebabCase(item.to)}`;

          if (item.children) {
            group = `${group}|${this.genGroup(item.children)}`;
            console.log('child');
          }

          console.log(group);
          return group;
        })
        .join('|');
    }
  }
};
</script>
