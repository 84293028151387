import { IStatisticData, StatisticIntervalType } from '@/models';
import { IStatisticService } from './statistic.types';
import { AxiosInstance } from 'axios';

export class StatisticService implements IStatisticService {
  private endpoint: string;

  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/notifications/devices/stats/';
  }

  fetchStatistics(
    created_after: Date,
    created_before: Date,
    interval: StatisticIntervalType
  ): Promise<IStatisticData> {
    return this.axios
      .get<IStatisticData>(`${this.endpoint}`, {
        params: {
          created_after: created_after,
          created_before: created_before,
          interval: interval
        }
      })
      .then(({ data }) => data);
  }
}
