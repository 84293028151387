import { IAd, IAdData } from '../ad.types';
import { Ad } from '../ad';

export const mockAdsData = (): IAdData[] => [
  {
    id: 1,
    image: 'http://localhost:8000/media/Volcan.jpg',
    url: 'url 1',
    order: 1,
    app: '73f10886-0f0a-45d8-ae35-03b18f86c2e5',
    updated_at: '2021-10-19T16:41:47.003639Z',
    created_at: '2021-10-19T16:41:47.003639Z'
  },
  {
    id: 2,
    image: 'http://localhost:8000/media/Volcan.jpg',
    url: 'message 2 longer text but not long enough',
    order: 3,
    app: '73f10886-0f0a-45d8-ae35-03b18f86c2e5',
    updated_at: '2021-10-19T16:41:47.003639Z',
    created_at: '2021-10-19T16:41:47.003639Z'
  },
  {
    id: 3,
    image: 'http://localhost:8000/media/Volcan.jpg',
    url: 'message 2 longer text but not long enough',
    order: 2,
    app: '73f10886-0f0a-45d8-ae35-03b18f86c2e5',
    updated_at: '2021-10-19T16:41:47.003639Z',
    created_at: '2021-10-19T16:41:47.003639Z'
  }
];

export const mockAds = (data: IAdData[] = mockAdsData()): IAd[] =>
  data.map((item) => new Ad(item));
