'use strict';

import Vue from 'vue';
import axios, { AxiosStatic, AxiosResponse } from 'axios';

// Full config:  https://github.com/axios/axios#request-config
const config = {
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 2 * 1000,
  headers: {
    'Content-Type': 'application/json'
  }
};

const _axios = axios.create(config);

Vue.use({
  install() {
    Vue.prototype.$axios = _axios;
  }
});

declare module 'vue/types/vue' {
  interface Vue {
    $axios: AxiosStatic;
  }
}

export { _axios as axios, AxiosResponse };
