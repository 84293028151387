// API Data
export interface IAdData {
  id?: number;
  image: string | File;
  url: string;
  app: string;
  order?: number;
  created_at?: Date | string;
  updated_at?: Date | string;
}

export interface IAdFieldErrorData {
  image?: string[];
  url?: string[];
  app: string[];
}

// Business Logic Interface
export interface IAd extends IAdData {
  validate(): boolean;
}

export interface IAdFieldError extends IAdFieldErrorData {
  clearErrors(): void;
}

// Constants - Used for validation
export const UUID_REGEXP =
  /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
