import { Module } from 'vuex';
import { IRootState } from '@/store';
import { IAuthState } from './auth.types';
import { IUserData } from '@/models';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: IAuthState = {
  version: 1,
  user: null as null | IUserData
};

const namespaced = true;

export const auth: Module<IAuthState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
