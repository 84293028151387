import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Store } from 'vuex/types/index';
import { IRootState } from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('@/views/Index.vue'),
    meta: {
      requiresAuth: true
    },
    redirect: { name: 'dashboard' },
    name: 'home',
    children: [
      {
        path: '/pages',
        component: () => import('@/views/pages/Pages.vue'),
        children: [
          {
            path: 'account/',
            name: 'account',
            component: () => import('@/views/pages/account/AccountPage.vue'),
            redirect: {
              name: 'account-profile'
            },
            children: [
              {
                path: 'profile',
                alias: 'profile',
                component: () =>
                  import('@/views/pages/account/profile/ProfilePage.vue'),
                redirect: {
                  name: 'account-profile'
                },
                children: [
                  {
                    path: '',
                    name: 'account-profile',
                    component: () =>
                      import(
                        '@/views/pages/account/profile/ProfileDefaultPage.vue'
                      )
                  },
                  {
                    path: 'password',
                    name: 'account-profile-password',
                    component: () =>
                      import(
                        '@/views/pages/account/profile/ProfileChangePasswordPage.vue'
                      )
                  },
                  {
                    path: 'name',
                    name: 'account-profile-name',
                    component: () =>
                      import(
                        '@/views/pages/account/profile/ProfileChangeNamePage.vue'
                      )
                  }
                ]
              }
            ]
          },
          {
            path: 'send-notification',
            name: 'send-notification',
            alias: 'notifications',
            component: () =>
              import('@/views/pages/notification/SendNotification.vue')
          },
          {
            path: 'dashboard',
            name: 'dashboard',
            component: () => import('@/views/pages/home/Dashboard.vue')
          },
          {
            path: 'advertisements',
            name: 'advertisements-list',
            component: () =>
              import('@/views/pages/advertisements/AdvertisementsListPage.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/legal',
    component: () => import('@/views/pages/Pages.vue'),
    children: [
      {
        path: 'privacy-policy',
        component: () => import('@/views/pages/legal/PrivacyPage.vue')
      }
    ]
  },
  {
    path: '/sessions',
    component: () => import('@/views/sessions/Sessions.vue'),

    children: [
      {
        path: '404',
        component: () => import('@/views/sessions/NotFound.vue')
      },
      {
        path: 'error',
        component: () => import('@/views/sessions/Error.vue')
      },
      {
        path: 'forgot',
        component: () => import('@/views/sessions/Forgot.vue')
      },
      {
        path: 'sign-in',
        name: 'login',
        component: () => import('@/views/sessions/SignIn.vue')
      },
      {
        path: 'sign-up',
        component: () => import('@/views/sessions/SignUp.vue')
      }
    ]
  },

  {
    path: '*',
    component: () => import('@/views/sessions/Sessions.vue'),
    redirect: {
      name: '404'
    },
    children: [
      {
        path: '',
        name: '404',
        component: () => import('@/views/sessions/NotFound.vue')
      }
    ]
  }
];

export const makeRouter = (store: Store<IRootState>) => {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
      if (to.hash) return { selector: to.hash };
      if (savedPosition) return savedPosition;

      return { x: 0, y: 0 };
    },
    routes
  });

  router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const isUserTokenExpired = await store.dispatch('auth/isUserTokenExpired');

    if (requiresAuth) {
      if (isUserTokenExpired) {
        return next('/sessions/sign-in');
      }
    }
    return next();
  });

  return router;
};
