import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { IRootState } from '@/store/store.types';

// modules
import largeSidebar from './modules/largeSidebar';
import compactSidebar from './modules/compactSidebar';
import verticalSidebar from './modules/verticalSidebar';
import themeConfig from './modules/themeConfig';
import authData from './modules/authData';
import { auth } from './modules/auth';
import { notification } from './modules/notification';
import { statistic } from './modules/statistic';
import { altoleapApp } from './modules/altoleapapp';
import { ad } from './modules/ad';

// Load Vuex
Vue.use(Vuex);

// Create store
export const makeStore = (): Store<IRootState> =>
  new Vuex.Store<IRootState>({
    state: {
      version: 1
    },

    mutations: {},
    actions: {},
    getters: {},

    modules: {
      largeSidebar,
      compactSidebar,
      verticalSidebar,
      themeConfig,
      authData,
      auth,
      notification,
      statistic,
      altoleapApp,
      ad
    }
  });
