import App from './App.vue';
import Vue from 'vue';
import { makeStore } from './store';
import { makeRouter } from './router';

import './plugins';
import vuetify from './plugins/vuetify';
import octaviaKit from '@/plugins/octavia.kit';
import { prepareServices } from './plugins/services';
import VueApexCharts from 'vue-apexcharts';
import { axiosInterceptorPlugin } from './plugins/vuexAxiosInterceptor';
import { axios } from './plugins/axios';

Vue.component('apexchart', VueApexCharts);
Vue.use(octaviaKit);
Vue.config.productionTip = false;
Vue.config.performance = process.env.NODE_ENV === 'development';

const store = makeStore();
prepareServices(store);

axiosInterceptorPlugin(axios, store);

const router = makeRouter(store);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
