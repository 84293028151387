import {
  IStatisticActions,
  StatisticContext,
  FetchStatisticsActionPayload
} from './actions.types';
import { IStatisticData } from '@/models';

export const actions: IStatisticActions = {
  fetchStatistics(
    context: StatisticContext,
    payload: FetchStatisticsActionPayload
  ): Promise<void> {
    return this.$services.statistic
      .fetchStatistics(
        payload.created_after,
        payload.created_before,
        payload.interval
      )
      .then((statistics: IStatisticData) => {
        context.commit('SET_STATISTICS', statistics);
      });
  }
};
