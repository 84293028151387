import { IAltoleapAppState } from '@/store/modules/altoleapapp';
import { IAltoleapAppGetters } from './getters.types';
import { IAltoleapApp, AltoleapApp } from '@/models';

export const getters: IAltoleapAppGetters = {
  getAltoleapApps(state: IAltoleapAppState): IAltoleapApp[] {
    return state.apps.map((app) => new AltoleapApp(app));
  },

  getAltoleapAppById:
    (state: IAltoleapAppState) =>
    (id: string): IAltoleapApp | undefined => {
      const data = state.apps.find((app) => app.id === id);
      if (!data) {
        return undefined;
      }

      return new AltoleapApp(data);
    }
};
