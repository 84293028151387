import {
  IAuthPasswordChangeFieldError,
  IAuthPasswordChangeFieldErrorData,
  IUser,
  User
} from '@/models';
import {
  IAuth,
  IAuthData,
  IAuthLoginFieldError,
  IAuthLoginFieldErrorData
} from './auth.types';

export class Auth implements IAuth {
  readonly token: string;
  readonly user: IUser;

  constructor(data: IAuthData) {
    this.token = data.token;
    this.user = new User(data.user);
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}

export class AuthLoginFieldError implements IAuthLoginFieldError {
  readonly email: string[] = [];
  readonly password: string[] = [];

  constructor(data?: IAuthLoginFieldErrorData) {
    if (data) {
      if (data.email) {
        this.email = data.email;
      }

      if (data.password) {
        this.password = data.password;
      }

      // Add the non field errors to password
      if (data.non_field_errors) {
        this.password = data.non_field_errors;
      }
    }
  }

  clearErrors(): void {
    this.email.splice(0, this.email.length);
    this.password.splice(0, this.password.length);
  }

  static isLoginFieldError(data: IAuthLoginFieldError): boolean {
    if (data.email && Array.isArray(data.email)) {
      return true;
    } else if (data.password && Array.isArray(data.password)) {
      return true;
    } else if (data.non_field_errors && Array.isArray(data.non_field_errors)) {
      return true;
    }

    return false;
  }
}

export class AuthPasswordChangeFieldError
  implements IAuthPasswordChangeFieldError
{
  readonly new_password1: string[] = [];
  readonly new_password2: string[] = [];

  constructor(data?: IAuthPasswordChangeFieldErrorData) {
    if (data) {
      if (data.new_password1) {
        this.new_password1 = data.new_password1;
      }

      if (data.new_password2) {
        this.new_password2 = data.new_password2;
      }
    }
  }

  clearErrors(): void {
    this.new_password1.splice(0, this.new_password1.length);
    this.new_password2.splice(0, this.new_password2.length);
  }

  static isPasswordChangeFieldError(
    data: IAuthPasswordChangeFieldError
  ): boolean {
    if (data.new_password1 && Array.isArray(data.new_password1)) {
      return true;
    } else if (data.new_password2 && Array.isArray(data.new_password2)) {
      return true;
    }

    return false;
  }
}
