import { INotificationActions, NotificationContext } from './actions.types';
import { INotificationData } from '@/models';

export const actions: INotificationActions = {
  fetchNotifications(context: NotificationContext): Promise<void> {
    return this.$services.notification
      .fetchNotifications()
      .then((notifications: INotificationData[]) => {
        context.commit('SET_NOTIFICATIONS', notifications);
      });
  },

  fetchNotification(
    context: NotificationContext,
    payload: number
  ): Promise<void> {
    return this.$services.notification
      .fetchNotification(payload)
      .then((notification: INotificationData) => {
        const index = context.state.notifications.findIndex(
          (v: INotificationData) => v.id === notification.id
        );
        if (index === -1) {
          context.commit('ADD_NOTIFICATION', notification);
        } else {
          context.commit('UPDATE_NOTIFICATION', notification);
        }
      });
  },

  createNotification(
    context: NotificationContext,
    payload: INotificationData
  ): Promise<void> {
    return this.$services.notification
      .createNotification(payload)
      .then((notification: INotificationData) =>
        context.commit('ADD_NOTIFICATION', notification)
      );
  },

  updateNotification(
    context: NotificationContext,
    payload: INotificationData
  ): Promise<void> {
    return this.$services.notification
      .updateNotification(payload)
      .then((notification: INotificationData) => {
        const index = context.state.notifications.findIndex(
          (v: INotificationData) => v.id === notification.id
        );
        if (index === -1) {
          context.commit('ADD_NOTIFICATION', notification);
        } else {
          context.commit('UPDATE_NOTIFICATION', notification);
        }
      });
  },

  deleteNotification(
    context: NotificationContext,
    payload: number
  ): Promise<void> {
    return this.$services.notification.deleteNotification(payload).then(() => {
      context.commit('REMOVE_NOTIFICATION', payload);
    });
  }
};
