import { INotificationData } from '@/models';
import { INotificationService } from './notification.types';
import { AxiosInstance } from 'axios';

export class NotificationService implements INotificationService {
  private endpoint: string;

  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/notifications/messages/';
  }

  fetchNotifications(): Promise<INotificationData[]> {
    return this.axios
      .get<INotificationData[]>(`${this.endpoint}`)
      .then(({ data }) => data);
  }

  fetchNotification(id: number): Promise<INotificationData> {
    return this.axios
      .get<INotificationData>(`${this.endpoint}${id}/`)
      .then(({ data }) => data);
  }

  createNotification(data: INotificationData): Promise<INotificationData> {
    return this.axios
      .post<INotificationData>(`${this.endpoint}`, data)
      .then(({ data }) => data);
  }

  updateNotification(data: INotificationData): Promise<INotificationData> {
    return this.axios
      .patch<INotificationData>(`${this.endpoint}${data.id}/`, data)
      .then(({ data }) => data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteNotification(id: number): Promise<any> {
    return this.axios.delete(`${this.endpoint}${id}/`);
  }
}
