import { StatisticsDetail } from './statistics-detail';
import { IStatisticsDetail } from './statistics-detail.types';
import {
  IStatistic,
  IStatisticData,
  IStatisticFieldError,
  IStatisticFieldErrorData
} from './statistic.types';

export class Statistic implements IStatistic {
  ios = 0;
  android = 0;
  web = 0;
  active = 0;
  inactive = 0;
  details: IStatisticsDetail[] = [];

  constructor(data?: IStatisticData) {
    if (data) {
      this.ios = data.ios ?? 0;
      this.android = data.android ?? 0;
      this.web = data.web ?? 0;
      this.active = data.active ?? 0;
      this.inactive = data.inactive ?? 0;
      this.details = data.details
        ? data.details.map((value) => new StatisticsDetail(value))
        : [];
    }
  }

  validate(): boolean {
    if (this.ios == null) {
      return false;
    }
    if (this.android == null) {
      return false;
    }
    if (this.web == null) {
      return false;
    }
    if (this.active == null) {
      return false;
    }
    if (this.inactive == null) {
      return false;
    }
    if (!this.details && !Array.isArray(this.details)) {
      return false;
    }
    return true;
  }
}

export class StatisticFieldError implements IStatisticFieldError {
  ios?: string[];
  android?: string[];
  web?: string[];
  active?: string[];
  inactive?: string[];
  details?: string[];

  constructor(data?: IStatisticFieldErrorData) {
    if (data) {
      if (data.ios) {
        this.ios = data.ios;
      }
      if (data.android) {
        this.android = data.android;
      }
      if (data.web) {
        this.web = data.web;
      }
      if (data.active) {
        this.active = data.active;
      }
      if (data.inactive) {
        this.inactive = data.inactive;
      }
      if (data.details) {
        this.details = data.details;
      }
    }
  }

  clearErrors(): void {
    this.ios?.splice(0, this.ios.length);
    this.android?.splice(0, this.android.length);
    this.web?.splice(0, this.web.length);
    this.active?.splice(0, this.active.length);
    this.inactive?.splice(0, this.inactive.length);
    this.details?.splice(0, this.details.length);
  }

  static isStatisticFieldError(data: IStatisticFieldErrorData): boolean {
    if (
      (data.ios && Array.isArray(data.ios)) ||
      (data.android && Array.isArray(data.android)) ||
      (data.web && Array.isArray(data.web)) ||
      (data.active && Array.isArray(data.active)) ||
      (data.inactive && Array.isArray(data.inactive)) ||
      (data.details && Array.isArray(data.details))
    ) {
      return true;
    }
    return false;
  }
}
