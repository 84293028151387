import {
  IOrganization,
  IUserFieldError,
  IUserFieldErrorData,
  IUserProfileFieldError,
  IUserProfileFieldErrorData,
  Organization
} from '@/models';
import {
  IUser,
  IUserData,
  IUserGroup,
  IUserGroupData,
  IUserProfile,
  IUserProfileData
} from './user.types';

export class UserGroup implements IUserGroup {
  readonly id?: number;
  readonly name: string;

  constructor(data: IUserGroupData) {
    if (data.id) {
      this.id = data.id;
    }

    this.name = data.name;
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}

export class UserProfile implements IUserProfile {
  readonly avatar?: Blob | string;
  readonly title: string = '';
  readonly bio: string = '';
  readonly phone: string = '';
  readonly website: string = '';
  readonly facebook: string = '';
  readonly instagram: string = '';
  readonly linkedin: string = '';
  readonly twitter: string = '';

  constructor(data?: IUserProfileData) {
    if (data) {
      if (data.avatar) {
        this.avatar = data.avatar;
      }

      if (data.title) {
        this.title = data.title;
      }

      if (data.bio) {
        this.bio = data.bio;
      }

      if (data.website) {
        this.website = data.website;
      }

      if (data.facebook) {
        this.facebook = data.facebook;
      }

      if (data.instagram) {
        this.instagram = data.instagram;
      }

      if (data.linkedin) {
        this.linkedin = data.linkedin;
      }

      if (data.twitter) {
        this.twitter = data.twitter;
      }
    }
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}

export class User implements IUser {
  readonly id?: number;
  readonly first_name: string = '';
  readonly last_name: string = '';
  readonly email: string = '';
  readonly profile: IUserProfile = new UserProfile();
  readonly groups: IUserGroup[] = [];
  readonly organization?: IOrganization;
  readonly is_admin?: boolean;
  readonly is_active?: boolean;
  readonly date_joined?: Date | string;
  readonly last_login?: Date | string;

  constructor(data: IUserData) {
    if (data.id) {
      this.id = data.id;
    }

    if (data.first_name) {
      this.first_name = data.first_name;
    }

    if (data.last_name) {
      this.last_name = data.last_name;
    }

    if (data.email) {
      this.email = data.email;
    }

    if (data.profile) {
      this.profile = new UserProfile(data.profile);
    }

    if (data.groups) {
      this.groups = data.groups.map((group) => new UserGroup(group));
    }

    if (data.organization) {
      this.organization = new Organization(data.organization);
    }

    if (data.is_admin) {
      this.is_admin = data.is_admin;
    }

    if (data.is_active) {
      this.is_active = data.is_active;
    }

    if (data.date_joined) {
      this.date_joined = data.date_joined;
    }

    if (data.last_login) {
      this.last_login = data.last_login;
    }
  }

  get fullName(): string {
    return `${this.first_name || ''} ${this.last_name || ''}`.trim();
  }

  get initials(): string {
    return this.fullName
      .toUpperCase()
      .split(' ')
      .map((n) => n[0])
      .join('');
  }

  validate(): boolean {
    throw new Error('Method not implemented.');
  }
}

export class UserFieldError implements IUserFieldError {
  readonly first_name: string[] = [];
  readonly last_name: string[] = [];
  readonly email: string[] = [];
  readonly organization: string[] = [];
  readonly profile: IUserProfileFieldError = new UserProfileFieldError();

  constructor(data?: IUserFieldErrorData) {
    if (data) {
      if (data.first_name) {
        this.first_name = data.first_name;
      }
      if (data.last_name) {
        this.last_name = data.last_name;
      }
      if (data.email) {
        this.email = data.email;
      }
      if (data.profile) {
        this.profile = new UserProfileFieldError(data.profile);
      }

      if (data.organization) {
        this.organization = data.organization;
      }
    }
  }

  clearErrors(): void {
    this.first_name.splice(0, this.first_name.length);
    this.last_name.splice(0, this.last_name.length);
    this.email.splice(0, this.email.length);
    this.profile.clearErrors();
    this.organization.splice(0, this.organization.length);
  }

  static isUserFieldError(data: IUserFieldError): boolean {
    if (data.first_name && Array.isArray(data.first_name)) {
      return true;
    }
    if (data.last_name && Array.isArray(data.last_name)) {
      return true;
    }
    if (data.email && Array.isArray(data.email)) {
      return true;
    }
    if (
      data.profile &&
      UserProfileFieldError.isUserProfileFieldError(data.profile)
    ) {
      return true;
    }

    if (data.organization && Array.isArray(data.organization)) {
      return true;
    }

    return false;
  }
}

export class UserProfileFieldError implements IUserProfileFieldError {
  readonly avatar: string[] = [];
  readonly title: string[] = [];
  readonly bio: string[] = [];
  readonly phone: string[] = [];
  readonly website: string[] = [];
  readonly facebook: string[] = [];
  readonly instagram: string[] = [];
  readonly linkedin: string[] = [];
  readonly twitter: string[] = [];

  constructor(data?: IUserProfileFieldErrorData) {
    if (data) {
      if (data.avatar) {
        this.avatar = data.avatar;
      }
      if (data.title) {
        this.title = data.title;
      }
      if (data.bio) {
        this.bio = data.bio;
      }
      if (data.phone) {
        this.phone = data.phone;
      }
      if (data.website) {
        this.website = data.website;
      }
      if (data.facebook) {
        this.facebook = data.facebook;
      }
      if (data.instagram) {
        this.instagram = data.instagram;
      }
      if (data.linkedin) {
        this.linkedin = data.linkedin;
      }
      if (data.twitter) {
        this.twitter = data.twitter;
      }
    }
  }

  clearErrors(): void {
    this.avatar.splice(0, this.avatar.length);
    this.title.splice(0, this.title.length);
    this.bio.splice(0, this.bio.length);
    this.phone.splice(0, this.phone.length);
    this.website.splice(0, this.website.length);
    this.facebook.splice(0, this.facebook.length);
    this.instagram.splice(0, this.instagram.length);
    this.linkedin.splice(0, this.linkedin.length);
    this.twitter.splice(0, this.twitter.length);
  }

  static isUserProfileFieldError(data: IUserProfileFieldError): boolean {
    if (data.avatar && Array.isArray(data.avatar)) {
      return true;
    }
    if (data.title && Array.isArray(data.title)) {
      return true;
    }
    if (data.bio && Array.isArray(data.bio)) {
      return true;
    }
    if (data.phone && Array.isArray(data.phone)) {
      return true;
    }
    if (data.website && Array.isArray(data.website)) {
      return true;
    }
    if (data.facebook && Array.isArray(data.facebook)) {
      return true;
    }
    if (data.instagram && Array.isArray(data.instagram)) {
      return true;
    }
    if (data.linkedin && Array.isArray(data.linkedin)) {
      return true;
    }
    if (data.twitter && Array.isArray(data.twitter)) {
      return true;
    }

    return false;
  }
}
